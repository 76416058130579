import React from "react";
import logo from "./assets/logo.jpg";
function App() {
  return (
    <>
      <div className="h-screen w-full bg-white text-gray-700 columns-1 md:columns-2">
        <div className="h-full bg-indigo-100 bg-blend-multiply bg-left bg-cover bg-[url('./assets/v.jpg')] hidden md:block">
          &nbsp;
        </div>
        <div className="h-full bg-white/60 bg-blend-overlay bg-left bg-cover bg-[url('./assets/v.jpg')] md:bg-white md:bg-none md:bg-blend-normal">
          <div className="h-full flex flex-col justify-center items-center">
            <article className="prose p-10 overflow-auto">
              <img src={logo} className="w-[100px] logo" alt="" />
              <h1>Experience the Essence of Luxury and Tranquility.</h1>
              <p className="text-base">
                Welcome to our hotel, where a world of indulgence and serenity
                awaits you.
                <br />
                As we diligently work behind the scenes to enhance your online
                experience, we invite you to join us on Instagram @
                <a
                  target="_blank"
                  href="https://instagram.com/villa_la_falaise"
                  rel="noreferrer"
                >
                  villa_la_falaise
                </a>
                . Immerse yourself in our captivating visuals, be inspired by
                our stunning accommodations, and stay up to date with the latest
                news and exclusive offers.
              </p>
              <p>
                For reservations, inquiries, or any assistance you may need,
                please don't hesitate to reach out to us directly at{" "}
                <a
                  target="_blank"
                  href="mailto:reservation@villa-lafalaise.com"
                  rel="noreferrer"
                >
                  reservation@villa-lafalaise.com
                </a>{" "}
                Our dedicated team is here to ensure your stay with us is
                nothing short of extraordinary.
              </p>
              <a
                type="button"
                href="https://villa-la-falaise.hotelrunner.com/bv3/search"
                className="no-underline px-3 py-2 font-semibold bg-gradient-to-r from-orange-400 to-yellow-400 hover:from-orange-500 hover:to-yellow-500"
              >
                Book now
              </a>
            </article>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
